import React from "react"
import { Link } from "gatsby"

import Image from "../image"


const Recommended = ({nodes}) => {

  if (!nodes || !nodes.length){
    return ''
  }

  const imagesPrefix = '../../images/';

  return (
    <div className="py-8">
      <div className="text-base text-gray-400 mb-2 flex items-center">
        <div>Thanks for reading! You might also like...</div>
        <div className="ml-auto">
          <Link to="/blog/" className="no-underline opacity-50 hover:opacity-100">View all</Link>
        </div>
      </div>
      <div className="grid gap-x-4 md:grid-cols-3 md:gap-x-5 md:gap-y-12">
        {nodes.map(n => n.node).map((node) => (
          <div key={node.frontmatter.slug} className="flex group flex-col rounded-lg shadow-lg overflow-hidden mb-4">
            <Link to={node.frontmatter.slug} className="flex-shrink-0 hidden sm:block">
              {node.frontmatter.imageUrl.startsWith(imagesPrefix) ? (
                <Image className="h-32 w-full object-cover filter grayscale-75 group-hover:grayscale-50" fileName={node.frontmatter.imageUrl.replace(imagesPrefix, '')} alt={node.frontmatter.title} />
              ) : (
                <img className="h-32 w-full object-cover filter grayscale-75 group-hover:grayscale-50" src={node.frontmatter.imageUrl} alt={node.frontmatter.title} />
              )}
            </Link>
            <div className="flex-1 bg-gray-800 text-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <div className="text-sm font-medium flex">
                  <div className="text-cyan-300 opacity-75">
                    {node.frontmatter.type === 'how-to' ? 'Product Guide'
                      : node.frontmatter.type === 'insight' ? 'Founder Insight'
                      : node.frontmatter.type === 'spotlight' ? 'Customer Spotlight'
                      : node.frontmatter.type === 'demo' ? 'Video Demo'
                      : node.frontmatter.type === 'podcast' ? 'Podcast Episode'
                      : ''}
                  </div>
                </div>
                <Link to={node.frontmatter.slug} className="block mt-2 no-underline">
                  <div className="text-md font-semibold text-white no-underline">{node.frontmatter.title}</div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Recommended;
